import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [

        {
            title: "Puķuzirņi",
            image: "https://i.imgur.com/dk2pHspb.jpg",
            native: "Vidusjūras apgabals",
            description: "Debešķīgi skaisti, bet vāzes nav ilgs dzīves ilgums aptuveni 4-5 dienas. Izrādās puķuzirņi radušies saulainajā Sicīlijā. Pasaulē ir pierasts dāvināt puķuzirņus, kā pateicības vai atzinības zīmi. Interesanti, ka Francijā līgavām, puķuzirņi simbolizē veiksmi, izturību un reizē arī atvadas",
        },        

        {
            title: "Krūmroze",
            image: "https://i.imgur.com/GNaV8mUb.jpg",
            native: "Āzija",
            description: "Viens no daudzajiem rožu veidiem. Daudzi mazāki ziedi uz viena stublāja, lieliski piešķir krāsu ziedu kompozīcijām. Ar mazo izmēru tās lieliski papildina lielākus ziedus. To krūmi var sasniegt pat divu metru augstumu. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Anemone",
            image: "https://i.imgur.com/JsEuT0Rb.jpg",
            native: "Vidusjūras apgabals",
            description: "Kaprīzas, bet ļoti izteiksmīgas. Pieminētas pat sengrieķu stāstos un citās valstīs tiek sauktas par vēja puķēm. Līdzīgi kā tulpes, tās turpina augt vāzē, tāpēc ap tām jāatstāj brīva vieta. Anemones ir skaistas, bet to mūžs vāzē nav ilgs 3-6 dienas.",
        },
        {
            title: "Delfīnijas",
            image: "https://i.imgur.com/U0hQwP1b.jpg",
            native: "Amerikas",
            description: "Eiropā parādījās ap 18.gs, kad to atveda no Tālajiem Austrumiem. Delfīnijas sastopamas dažādās krāsās, tomēr dominējošā ir zilā. Interesanti, ka delfīnijas arī Latvijā var ziedēt pat 2 reizes gadā, pirmo reizi ap Jāņiem, otro - rudenī. Griezto ziedu ilgums vāzē 4-12 dienas.",
        },
        {
            title: "Plīvurpuķe jeb ģipsene",
            image: "https://i.imgur.com/zqKob2gb.jpg",
            description: "To mēdz dēvēt par kaprīzo skaistuli no vecmāmiņas dārza. Tas ir cimperlīgs augs, ja vēlies to stādīt dārzā, bet kad tas uzzied, tad dārzs izskatās, kā balta, krāšņa kupena.",
        },        
        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Šoreiz izvēlējāmies divu krāsu rozes, kuru krāsa izcili papildina kompozīcijas toņus. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },

        {
            title: "Nauduļi",
            image: "https://i.imgur.com/jcvPQ9ab.jpg",
            native: "Amerikas un Eirāzija",
            description: "Īpaši trausli. Augs zied ar maziem ziloņkrāsas ziediņiem. To ziedu kompozīcijās izmanto, kā zaļo aizpildītāju, lai kompozīcija izksatītos apjomīgāka.",
        },



            
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/a1631ab3-50ae-42e5-be8c-a6f716807a0c/playlist.m3u8"
                        captions="/titles/009.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Francijas brīvdienas</h2>

                            <p>Galvenie šīs kompozīcijas ziedi ir īpaši raksturīgi Dienvideiropai, tāpēc tie mums atgādina lieliskos atvaļinājumus Francijā un Italijā, kurus nevaram sagaidīt atkal!</p>
                            
                            <p>Nupat kā parādījušies puķuzirņi ir kārtējie pavasara vēstneši, kuri šoreiz akcentēs ar savu dzidro baltumu. Otrā krāsu spektra galā, tumšās anemones, kas pašlaik ir pašā sezonas pilnbriedā, piešķirs dziļumu un dūmakainumu.</p>
                            
                            <p>Kopumā šīs nedēļas kompozīcija ietver ļoti atšķirīgus un dažādus ziedus, bet domājam, ka tie lieliski sader kopā. Ceru ka Tev izdosies skaisti, turklāt, pateicoties nokaranājām rozēm, kompozīcijas izveide noteikti būs jautra.</p>


                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Delfīnijas ir īpaši jūtīgas pret etilēnu. Tāpēc nenovieto svaigus augļus telpā, kurā ir novietota šī kompozīcija</li>
                                <li>Protams, ir vairāki veidi kā sakārtot šos ziedus, mūsu video apskatāms vairāk vertikālas kompozīcijas izveidei. Bet šie ziedi būs piemēroti arī brīvākas kompozīcijas izveidē, izmantojot platāku vāzi.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba:</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi. Griez slīpi, 45° lenķī</li>

                                <li>Ziedu garums ir atkarīgs no vāzes, bet delfīnijām jābūt visgarākajām. Rozēm, anemonēm jābūt vienā garumā, īsākiem par delfīnijām. Puķuzirņi jau ir visīsākie</li>

                                <li>Sāc ar rozēm, ko izkārto pa vāzes perimetru, krustojot to kātus vāzē, lai izveidotos režģis, kas balstīs citus cietus</li>
                                <li>Vidū, vienu garāku un otru īsāku, ievieto plīvurpuķi, lai tā viscaur piešķir apjomu un balto akcentu</li>

                                <li>Pabeidzot kompozīcijas pamatu, ievieto zaļumus - nauduļus</li>
                                <li>Tālāk ņemam garās delfīnijas, kuras mēs likām kompozīcijas aizmugurē, jo tā būtu novietota pie sienas, bet, ja kompozīcija būs galda dekors, tās vari izretināt viscaur kompozīcijai</li>

                                <li>Kaut arī šķiet, ka nokarenās rozes nebūs kur ielikt, to ziedi atbalstīsies uz citiem ziediem, tāpēc vari tās ievietot pašā centrā, un ļaut lai ziedi "nokrīt" sev piemērotajās vietās</li>

                                <li>Tumšos akcentus, anemones, izkārto starp rozēm.</li>
                                <li>Visbeidzot, piešķir mirdzumu ar spilgti baltajiem puķu zirņiem. Tie ir īsāki, tāpēc derēs vāzes malās</li>
                                <li>Pēc puķu zirņu ievietošanas, pārbaudi vai visi ziedi ir ūdenī. Ja nav, papildini ūdeni, vai, izņemot visu kompozīciju kopā, apgriez garākos</li>
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram!</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/OeGuNQzh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p><strong>Ņem vērā: Delfīnijas ir īpaši jūtīgas pret etilēnu. Tāpēc nenovieto svaigus augļus telpā, kurā ir novietota šī kompozīcija.</strong></p>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
